/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import clsx from 'clsx'
import axios from 'axios'
import Loader from 'react-loader-spinner'
import Moment from 'moment'

const API_URL = process.env.REACT_APP_SERVER_HOST

var _self: any = {}

class ChatInner extends Component<any, any> {
  constructor(props: any) {
    super(props)
    _self.chat_el = React.createRef()
    const senderId = localStorage.getItem('senderId') as string
    this.state = {
      isDrawer: props.isDrawer,
      chat_from_user_id: props.all_info.chat_from_user_id,
      chat_to_user_id: props.all_info.chat_to_user_id ? props.all_info.chat_to_user_id : '',
      unique_id: props.all_info.unique_id ? props.all_info.unique_id : '',
      current_user_role: props.all_info.current_user_role,
      opposite_user_role: props.all_info.opposite_user_role,
      current_user_name: props.all_info.current_user_name,
      message: '',
      chatList: [],
      senderId: props.all_info.chat_to_user_id ? props.all_info.chat_to_user_id : '',
      from_name: '',
      to_name: '',
      prosConn: props.socketCon,
    }
    _self = this
  }

  componentDidMount() {
    const requestParam = {
      chat_from_user_id: this.state.chat_from_user_id,
      chat_to_user_id: this.state.chat_to_user_id,
      sender_user: this.state.current_user_role,
      request_to: 'reapit',
    }
    if (this.state.chat_to_user_id && this.state.unique_id) {
      this.fetchChatData(requestParam)
    }
    if (this.state.prosConn !== undefined) {
      _self.prosConn = this.state.prosConn
      _self.prosConn.onmessage = _self.onMessage.bind(_self)
    }

    _self.scrollToBottom()
  }

  fetchChatData = async (requestParam) => {
    // this.setState({
    //   isLoader: true,
    // })

    await axios.post(API_URL + 'chat', requestParam).then((response) => {
      _self.setState({
        // isLoader: false,
        chatList: response.data.chatList ? response.data.chatList : [],
        from_name: response.data.from_name,
        to_name: response.data.to_name,
      })

      _self.chat_el.scrollIntoView(false)
    })
  }

  scrollToBottom = () => {
    const chatContainer = document.getElementById('test_div')
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight
    }
  }

  sendMessage = () => {
    const messageData = {
      command: 'prospect_chat',
      from: this.state.chat_from_user_id,
      to: this.state.chat_to_user_id,
      message: this.state.message,
      sender_user: this.state.current_user_role,
      request_to: 'server',
      sender_name: this.state.current_user_name,
    }

    _self.onSend(messageData)
    this.setState({message: ''})
    axios.post(API_URL + 'chat_add', messageData).then((response) => {
      const requestParam = {
        chat_from_user_id: this.state.chat_from_user_id,
        chat_to_user_id: this.state.chat_to_user_id,
        sender_user: this.state.current_user_role,
        request_to: 'reapit',
      }
    })

    _self.scrollToBottom()
  }

  onMessage(event) {
    const socketData = JSON.parse(event.data)
    if (socketData.to == this.state.reapit_id) {
      localStorage.setItem('chat_read', 'unread')
      var element = (document.getElementById('chat_unread') as HTMLCanvasElement) || null
      element.style.display = 'block'
    }

    if (socketData.command) {
      const currentDate = new Date()
      const formattedDateTime = currentDate.toISOString().slice(0, 19).replace('T', ' ')
      const newChatData = {
        id: 344,
        chat_id: socketData.from + '_' + socketData.to,
        sender_name: socketData.sender_name,
        from: socketData.from,
        to: socketData.to,
        sender_user: socketData.sender_user,
        message: socketData.message,
        created_at: formattedDateTime,
        updated_at: formattedDateTime,
      }

      const updatedChatList = [...this.state.chatList, newChatData]

      // Update the state with the new array
      this.setState({chatList: updatedChatList})
      _self.scrollToBottom()
    }
  }

  onSend(socketData) {
    _self.prosConn.send(JSON.stringify(socketData))
  }

  handleTextMessage = (message) => {
    this.setState({
      message: message,
    })
  }

  render() {
    const isDrawer = this.state.isDrawer
    const chatList = this.state.chatList
    return (
      <div
        className='card-body px-0'
        id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
      >
        <div
          id='test_div'
          className={clsx('scroll-y me-n5 pe-5 p-5 ', {'h-300px h-lg-auto': !isDrawer})}
          data-kt-element='messages'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies={
            isDrawer
              ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
              : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
          }
          data-kt-scroll-wrappers={
            isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
          }
          data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
          ref={(chat_el) => {
            _self.chat_el = chat_el
          }}
        >
          {this.state.isLoader === true ? (
            <div className='propertyLoader' style={{textAlign: 'center', margin: '50px'}}>
              <Loader type='Oval' color='#B0E670' height={150} width={150} />
            </div>
          ) : Object.keys(chatList).length !== 0 ? (
            chatList.map((chatData, chatKey) => {
              var fromName = this.state.from_name
                .split(' ')
                .map((i) => i.charAt(0))
                .toString()
                .toUpperCase()
                .split(',')
              if (fromName.length >= 3) {
                fromName = fromName.toString().substring(1)
                fromName = fromName.replaceAll(',', '')
              }

              var to_name = this.state.to_name
                .split(' ')
                .map((i) => i.charAt(0))
                .toString()
                .toUpperCase()
                .split(',')
              if (to_name.length >= 3) {
                to_name = to_name.toString().substring(1)
                to_name = to_name.replaceAll(',', '')
              }

              return (
                <div key={chatKey}>
                  {chatData.sender_user == this.state.current_user_role ? (
                    <div className='d-flex  justify-content-end mb-10 mb-10'>
                      <div className='d-flex flex-column align-items align-items-end'>
                        <div className='d-flex align-items-center mb-2'>
                          <div className='me-3'>
                            <span className='text-muted fs-7 mb-1'>
                              {Moment(chatData.created_at).format('d MMM h:m')}
                            </span>
                            <span className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'>
                              {this.state.from_name}
                            </span>
                          </div>
                          <div className='symbol symbol-40px me-4'>
                            <div className='symbol-label fs-2 fw-semibold bg-danger text-inverse-danger'>
                              {fromName}
                            </div>
                          </div>
                        </div>
                        <div
                          className='p-5 rounded bg-light-primary text-dark fw-bold mw-lg-400px text-end'
                          data-kt-element='message-text'
                        >
                          {chatData.message}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='d-flex  justify-content-start mb-10 mb-10'>
                      <div className='d-flex flex-column align-items align-items-start'>
                        <div className='d-flex align-items-center mb-2'>
                          <div className='symbol symbol-40px me-4'>
                            <div className='symbol-label fs-2 fw-semibold bg-danger text-inverse-danger'>
                              {to_name}
                            </div>
                          </div>
                          <div className='ms-3'>
                            <a
                              href='#'
                              className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                            >
                              {this.state.to_name}
                            </a>
                            <span className='text-muted fs-7 mb-1'>
                              {Moment(chatData.created_at).format('d MMM h:m')}
                            </span>
                          </div>
                        </div>
                        <div
                          className='p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start'
                          data-kt-element='message-text'
                        >
                          {chatData.message}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )
            })
          ) : null}
        </div>

        <div
          className='card-footer pt-4 p-5'
          id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
        >
          <textarea
            className='form-control border-0 rounded-lg bg-prgrey fs-7 min-h-100px mb-5'
            rows={1}
            data-kt-element='input'
            placeholder='Type a message'
            id='send_textarea'
            value={this.state.message}
            onChange={(e) => this.handleTextMessage(e.target.value)}
          ></textarea>

          <div className='d-flex flex-stack'>
            <div className='d-flex align-items-center me-2'></div>
            {this.state.chat_to_user_id && (
              <button
                className='btn btn-primary'
                type='button'
                data-kt-element='send'
                onClick={this.sendMessage}
              >
                Send
              </button>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export {ChatInner}
