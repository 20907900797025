import React, {Component} from 'react'
import {Link, withRouter} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'

var _self: PageImageHeader

class PageImageHeader extends Component<any, any> {
  constructor(props) {
    super(props)
    this.state = props
    this.state = {
      title: this.props.title,
      image: this.props.image,
      backgroundPosition: this.props.backgroundPosition,
      subheading: this.props.subheading,
      className: [],
    }

    _self = this
  }
  render() {
    const className = this.props.className
    const title = this.props.title
    const subheading = this.props.subheading
    const image = this.props.image
    const backgroundPosition = this.props.backgroundPosition

    const text_flex =
      title === 'Contact Us'
        ? 'justify-content-start'
        : title === 'Chat with an agent'
        ? 'justify-content-start'
        : 'justify-content-end';
    const title_text_color = title === 'Chat with an agent'? "text-white" : "";
    return (
      <>
        {/* begin::Stats */}

        <div
          className='card w-100 rounded-0 d-flex align-items-center mt-n20 position-relative text-left text-gray bgi-size-cover pt-5 p-5 image-head-box'
          style={{
            backgroundImage: `url('${toAbsoluteUrl(image)}')`,
            ...(backgroundPosition && { backgroundPosition: backgroundPosition })
          }}
        >
          {/* begin::Row */}
          <div className={`d-flex align-items-center ${text_flex} w-100 min-h-150px`}>
            <div className='col-6 col-md-6 text-center'>
              <h2 className={`fs-1x text-normal lh-1 ${title_text_color}`}>{title}</h2>
              <p className='align-items-end lh-1 fs-1x'>{subheading}</p>
            </div>
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}
      </>
    )
  }
}

export default withRouter(PageImageHeader)
