/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
// import {login,storefcmtocken} from '../redux/AuthCRUD'
import {requestPermission} from '../../../firebase/messaging-get-token'
import {login, storefcmtocken} from '../redux/AuthCRUD'
//import {refreshToken} from '../redux/AuthCRUD'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Capacitor} from '@capacitor/core'
import {
  PushNotificationSchema,
  PushNotifications,
  Token,
  ActionPerformed,
} from '@capacitor/push-notifications'
import {Toast} from '@capacitor/toast'
import AppleLogin from 'react-apple-login'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications')

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const handleAppleResponse = (response) => {
    // Handle the response from Apple's authentication here
    console.log('apple response', response)
  }
  // const currentURL = window.location.href;
  // const redirectURI = currentURL + '?redirect_uri=https://app.prospect.co.uk/auth/login';
  const currentURL = 'https://prospectapp.tpmadev.co.uk/auth/login'

  // ...

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        login(values.email, values.password)
          .then(async (response) => {
            if (response.data.status) {
              const accessToken = response.data.success.token
              localStorage.setItem('accessToken', accessToken)
              setLoading(false)

              dispatch(auth.actions.login(accessToken))
              // const token = await requestPermission();
              // storefcmtocken(token)
              //refreshToken();
              console.log('isPushNotificationsAvailable: ' + isPushNotificationsAvailable)
              if (isPushNotificationsAvailable) {
                // Register with Apple / Google to receive push via APNS/FCM
                await PushNotifications.register()

                // On success, we should be able to receive notifications
                await PushNotifications.addListener('registration', (token: Token) => {
                  console.log('Push registration success, token: ' + token.value)
                  storefcmtocken(token.value)
                  localStorage.setItem('fcmToken', token.value)
                })

                await PushNotifications.addListener('registrationError', (err) => {
                  console.error('Registration error: ', err.error)
                })
              } else {
                console.log('isPushNotificationsAvailable is false')
              }
            } else {
              setLoading(false)
              setSubmitting(false)
              setStatus(response.data.error)
            }
          })
          .catch(function (error) {
            setLoading(false)
            setSubmitting(false)
            if(error.response && error.response.data && error.response.data.error){
              setStatus(error.response.data.error)
            }else{
              setStatus('The login details could not be found. Please check and try again.')
            }
            
            
          })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Sign In to Prospect App</h1>
          <div className='text-gray-400 fw-bold fs-4'>
            <Link to='/auth/registration' className='link-primary fw-bolder'>
              Create an Account
            </Link>
          </div>
        </div>

        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : null}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid text-dark',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
              {/* end::Label */}
              {/* begin::Link */}
              <Link
                to='/auth/forgot-password'
                className='link-primary fs-6 fw-bolder'
                style={{marginLeft: '5px'}}
              >
                Forgot Password ?
              </Link>
              {/* end::Link */}
            </div>
          </div>
          <input
            placeholder='Password'
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid text-dark',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            //disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>

      <AppleLogin
        clientId='com.prospect.properties'
        redirectURI={currentURL}
        usePopup={true}
        callback={handleAppleResponse} // Catch the response
        scope='email name'
        responseMode='query'
        render={(
          renderProps //Custom Apple Sign in Button
        ) => (
          <button className='apple-button' onClick={renderProps.onClick}>
            <span className='apple-logo'>
              <i className='fa-brands fa-apple px-2 '></i>
            </span>
            Continue with Apple
          </button>
        )}
      />
    </>
  )
}
