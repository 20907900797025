/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useDispatch} from 'react-redux'

export function AsideMenuProfile() {
  const intl = useIntl()
  return (
    <>
       <AsideMenuItem
       to='/forms/contact' 
       title='Contact' />
      <AsideMenuItem 
       to='#'
       title='Chat'
       disableClick={true}
       id='kt_drawer_chat_toggle'
       />
       <AsideMenuItem 
       to='/profile/overview' 
       title='Profile'  />
       {/*<AsideMenuItem 
       to='/profile/overview' 
  title='Account Settings' />*/}
    </>
  )
}
