/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../_metronic/helpers'

var _self: PropertyItem

class PropertyItem extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = props
  }

  render() {
    const propertyData = this.state
    return (
      <Link to={`/properties/detail/${propertyData.id}`} className='card p-5'>
        <div
          className='bgi-no-repeat bgi-size-cover min-h-250px'
          style={{backgroundImage: `url(${propertyData.icon})`}}
        ></div>

        <div className='card-body p-0 mt-2'>
          <div className='card-title m-0'>
            <div className='fs-3 text-dark'>{propertyData.title}</div>
            <div className='fs-6 text-gray-800 fw-bolder'>{propertyData.budget}</div>
          </div>

          <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7 '>
            {propertyData.description.substring(0, 150)}
          </p>
          <div className='d-flex flex-column'>
            <div className='d-flex flex-wrap'>
              <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-4 mb-3'>
                <div className='fs-6 text-gray-800 fw-bolder'>
                  {propertyData.bedrooms}
                  <span className='svg-icon svg-icon-primary svg-icon-2x ms-2'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24px'
                      height='24px'
                      viewBox='0 0 24 24'
                      version='1.1'
                    >
                      <title>Stockholm-icons / Home / Bed</title>
                      <desc>Created with Sketch.</desc>
                      <defs />
                      <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                        <rect x='0' y='0' width='24' height='24' />
                        <path
                          d='M4,22 L2,22 C2,19.2385763 4.23857625,18 7,18 L17,18 C19.7614237,18 22,19.2385763 22,22 L20,22 C20,20.3431458 18.6568542,20 17,20 L7,20 C5.34314575,20 4,20.3431458 4,22 Z'
                          fill='#000000'
                          fill-rule='nonzero'
                          opacity='0.3'
                        />
                        <rect fill='#000000' x='1' y='14' width='22' height='6' rx='1' />
                        <path
                          d='M13,13 L11,13 L11,12 C11,11.4477153 10.5522847,11 10,11 L6,11 C5.44771525,11 5,11.4477153 5,12 L5,13 L4,13 C3.44771525,13 3,12.5522847 3,12 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12 C21,12.5522847 20.5522847,13 20,13 L19,13 L19,12 C19,11.4477153 18.5522847,11 18,11 L14,11 C13.4477153,11 13,11.4477153 13,12 L13,13 Z'
                          fill='#000000'
                          opacity='0.3'
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-4 mb-3'>
                <div className='fs-6 text-gray-800 fw-bolder'>
                  {propertyData.bathrooms}
                  <span className='svg-icon svg-icon-primary svg-icon-2x ms-2'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24px'
                      height='24px'
                      viewBox='0 0 24 24'
                      version='1.1'
                    >
                      <title>Stockholm-icons / Home / Bath</title>
                      <desc>Created with Sketch.</desc>
                      <defs />
                      <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                        <path
                          d='M6,10 C6,10.5522847 5.55228475,11 5,11 C4.44771525,11 4,10.5522847 4,10 L4,6 C4,4.34314575 5.34314575,3 7,3 C8.65685425,3 10,4.34314575 10,6 C10,6.55228475 9.55228475,7 9,7 C8.44771525,7 8,6.55228475 8,6 C8,5.44771525 7.55228475,5 7,5 C6.44771525,5 6,5.44771525 6,6 L6,10 Z'
                          fill='#000000'
                          fill-rule='nonzero'
                          opacity='0.3'
                        />
                        <path
                          d='M6,20 L4,20 C4,17.2385763 6.23857625,16 9,16 L15,16 C17.7614237,16 20,17.2385763 20,20 L18,20 C18,18.3431458 16.6568542,18 15,18 L9,18 C7.34314575,18 6,18.3431458 6,20 Z'
                          fill='#000000'
                          fill-rule='nonzero'
                          opacity='0.3'
                        />
                        <path
                          d='M2,9 L23,9 L22.4942047,13.5521576 C22.2128532,16.084321 20.0725321,18 17.524786,18 L7.47521397,18 C4.92746788,18 2.78714678,16.084321 2.50579529,13.5521576 L2,9 Z M19.25,11.5 C19.25,12.9305987 18.694479,14.0416408 17.55,14.9 C17.2186292,15.1485281 17.1514719,15.6186292 17.4,15.95 C17.6485281,16.2813708 18.1186292,16.3485281 18.45,16.1 C19.9721877,14.9583592 20.75,13.4027346 20.75,11.5 C20.75,11.0857864 20.4142136,10.75 20,10.75 C19.5857864,10.75 19.25,11.0857864 19.25,11.5 Z'
                          fill='#000000'
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-4 mb-3'>
                <div className='fs-6 text-gray-800 fw-bolder'>
                  {propertyData.receptions}
                  <span className='svg-icon svg-icon-primary svg-icon-2x ms-2'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24px'
                      height='24px'
                      viewBox='0 0 24 24'
                      version='1.1'
                    >
                      <title>Stockholm-icons / Home / Couch</title>
                      <desc>Created with Sketch.</desc>
                      <defs />
                      <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                        <path
                          d='M6,20 L4,20 C4,17.2385763 6.23857625,16 9,16 L15,16 C17.7614237,16 20,17.2385763 20,20 L18,20 C18,18.3431458 16.6568542,18 15,18 L9,18 C7.34314575,18 6,18.3431458 6,20 Z'
                          fill='#000000'
                          fill-rule='nonzero'
                          opacity='0.3'
                        />
                        <path
                          d='M23,8 L21.173913,8 C20.0693435,8 19.173913,8.8954305 19.173913,10 L19.173913,12 C19.173913,12.5522847 18.7261978,13 18.173913,13 L5.86956522,13 C5.31728047,13 4.86956522,12.5522847 4.86956522,12 L4.86956522,10 C4.86956522,8.8954305 3.97413472,8 2.86956522,8 L1,8 C1,6.34314575 2.34314575,5 4,5 L20,5 C21.6568542,5 23,6.34314575 23,8 Z'
                          fill='#000000'
                          opacity='0.3'
                        />
                        <path
                          d='M23,10 L23,15 C23,16.6568542 21.6568542,18 20,18 L4,18 C2.34314575,18 1,16.6568542 1,15 L1,10 L2.86956522,10 L2.86956522,12 C2.86956522,13.6568542 4.21271097,15 5.86956522,15 L18.173913,15 C19.8307673,15 21.173913,13.6568542 21.173913,12 L21.173913,10 L23,10 Z'
                          fill='#000000'
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    )
  }
}

export default PropertyItem
