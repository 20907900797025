import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {AuthPage} from '../modules/auth'
import HomePage from '../public/HomePage';

export function PublicRoutes() {
  return (
    <Switch>      
      <Route path='/auth' component={AuthPage} />       
      <Route path='/' component={HomePage} exact/> 
      <Redirect to='/' />    
    </Switch>
  )
}
