import axios from 'axios';
import {AuthModel} from '../models/AuthModel';
import {UserModel} from '../models/UserModel';
import jwt from 'jwt-decode';

const API_URL = process.env.REACT_APP_SERVER_HOST;
const GET_TOKEN_URL =`${API_URL}token` ;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/get-user`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}password/email`
export const RESET_PASSWORD_URL = `${API_URL}/password/reset`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(API_URL + "login", {email, password})
}

// store token in backend
export function storefcmtocken(fcm_token?: string | undefined) {
  return axios.post(API_URL + "store-fcm-token", {fcm_token}, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  })
}
export function deleteAccount() {
  return axios.post(API_URL + "delete-account", {},{
    headers: {
      'accept' : 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post(API_URL + 'register', {
    email,
    firstname,
    lastname,
    password,
    c_password: password,
    name: firstname
  })
}
// Server should return AuthModel
export function resetPassword(email: string, password: string, token:string) {
  return axios.post(API_URL + 'password/reset', {
    email,
    password,
    password_confirmation: password,
    token
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{status: boolean,message:string}>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  refreshToken();
  return axios.post(API_URL + 'details',{"request_to":"server"});
}

export async function refreshToken(){
  
 var reapitAccessToken = localStorage.getItem("reapitAccessToken");

  var tokenExpire = false;
  if(reapitAccessToken){
    type JWTDeCode  = {
          id: string,
          email: string,
          iat: number,
          exp: number
      }
      const decoded : JWTDeCode = jwt(`${reapitAccessToken}`);        
      if (decoded.exp < Date.now() / 1000) {
          tokenExpire = true;
      }
  }

  if(!reapitAccessToken || tokenExpire === true){
      tokenExpire = false;
      const res = await axios.post(`${GET_TOKEN_URL}`);
      if(res.status === 200){          
          localStorage.setItem('reapitAccessToken', res.data.access_token)
          return res.data.access_token
      }
  }

  if(tokenExpire === false){
      return reapitAccessToken;
  }
}