/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios';
import Loader from "react-loader-spinner";
import PropertyItem from './PropertyItem';
import {PublicHeader} from './PublicHeader';


var _self: OnSaleProperty;

const API_URL = process.env.REACT_APP_SERVER_HOST;
const GET_PROPERTY_URL =`${API_URL}property`;

class OnSaleProperty extends Component<any, any> {

    constructor(props){
        super(props);

        this.state = {
            propertyData: [],
            propertyImagesObj: [],
            pageNumber: 1,  
            pageSize: 10,  
            pageCount: 6,  
            totalPageCount: 500,
            totalCount: 0,
            isLoader: true,        
            filterData: [],        
        };
        _self = this;
        this.fetchProperty = this.fetchProperty.bind(this);
    }

    componentDidMount(){
        const requestBody = {
            "pageNumber": 1
        };
        this.fetchProperty(requestBody);
    }

    fetchProperty(requestBody){ 
        requestBody.request_to = 'reapit';   
        requestBody.marketingMode = 'selling';
        requestBody.sellingStatus = 'forSale';
        requestBody.pageSize = requestBody.pageSize ? requestBody.pageSize : this.state.pageSize;    
        requestBody.search = requestBody.search == '' || requestBody.search ? requestBody.search : this.state.search;     
        if(this.state.filterData.length > 0){
            requestBody = requestBody.concat(this.state.filterData);
        }
        _self.setState({
            isLoader: true,
        })
        axios.get(
        `${GET_PROPERTY_URL}`,{ params: requestBody})
            .then(function(response) {
                if(response.status === 200){
                    _self.setState({
                        propertyData: response.data._embedded ? response.data._embedded : [],
                        pageNumber: response.data.pageNumber ? response.data.pageNumber : 1,
                        pageSize: response.data.pageSize ? response.data.pageSize : 9,
                        pageCount: response.data.pageCount ? response.data.pageCount : 9,
                        totalPageCount: response.data.totalPageCount ? response.data.totalPageCount : 0,
                        totalCount: response.data.totalCount ? response.data.totalCount : 0,
                        isLoader: false,
                    });
                }
            }
        );
    }

    createPagination = (totalPageCount) => {
        var pageCollection:any[] = [];
        var pageTill = totalPageCount >= 6 ? this.state.pageNumber + 6 : totalPageCount;

        this.state.pageNumber > 1 &&
            pageCollection.push(
                <li className="page-item previous">
                    <a href="#" className="page-link" onClick={ (event) => this.onPageSelect(event , this.state.pageNumber - 1) }>
                        <i className="previous"></i>
                    </a>
                </li>
            );
        

        for(let pageCount = this.state.pageNumber; pageCount <= pageTill; pageCount++){
            pageCollection.push(
                <li className={this.state.pageNumber === pageCount ? "page-item active" : "page-item" }>
                    <a href='#' className="page-link" onClick={ (event) => this.onPageSelect(event , pageCount) }>
                        {pageCount}
                    </a>
                </li>
            );
        }

        this.state.totalPageCount >= 6 && 
            pageCollection.push(
                <li className="page-item next">
                    <a href="#" className="page-link" onClick={ (event) => this.onPageSelect(event , this.state.pageNumber + 1) }>
                        <i className="next"></i>
                    </a>
                </li>
            );
        

        return pageCollection;
    }

    onPageSelect = (event,pageCount) => {
        event.preventDefault();
        const requestBody = {        
            "pageNumber": pageCount
        };
        this.fetchProperty(requestBody);

        var activePageElement = document.querySelectorAll('.page-item.active');
        activePageElement[0].classList.remove('active');
        event.currentTarget.parentElement.classList.add("active");
    }

    handlePageChange = (value) => {	

        this.setState({
             "pageSize": value
        })
        const requestBody = {
            "pageNumber": 1,
            "pageSize": value,
        };
        this.fetchProperty(requestBody);	
    }

    handleSearchText = (value) => {
		if(value.length > 3){	
            this.setState({
                "search": value
            })	
			const requestBody = {
                "pageNumber": 1,
                "search": value,                
            };
            this.fetchProperty(requestBody);
		} 
        
        if(value.length == 0 || value == '') {
            this.setState({
                "search": ''
            })
			const requestBody = {
                "pageNumber": 1, 
                "search": ''             
            };
            this.fetchProperty(requestBody);
		}
    }

    handleFilterProperty = (filterData) => {		
        this.fetchProperty(filterData);		
    }

    render(){
        const propertyData = this.state.propertyData;
        return(
            
            <div className='d-flex flex-column flex-root bg-body w-100' id='kt_toolbar'>
                <PublicHeader handleSearchText = {_self.handleSearchText} handleFilterProperty = {_self.handleFilterProperty}/>
                 <div className='row'>
                <div className='col-sm-12 col-xl-12 text-center'>
                   This
                        </div>
                </div>
                <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed bg-prgreymed'>        
                    <div className='d-flex flex-column flex-column-fluid p-10 py-lg-20'>
                    <h2>Properties for sale</h2>

                    <div className='container row g-6 g-xl-9 mx-auto'>
                        <div className='col-md-6 col-xl-11'></div>		
                        <div className='col-md-6 col-xl-3'>                            
                            <select className='form-control me-2 my-5' onChange={(e) => _self.handlePageChange(e.target.value)}>
                                <option value={10}>Per Page</option>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                            </select>
                        </div>                        
                    </div>

                    {
                        this.state.isLoader == true ?
                            <div className='propertyLoader' style={{"textAlign":"center","margin":"50px"}}>
                                <Loader
                                    type="Oval"
                                    color="#B0E670"
                                    height={150}
                                    width={150}
                                />
                            </div>
                        :
                        <>                    
                            <div className='container row g-6 g-xl-9 mx-auto'>
                                {
                                    Object.keys(propertyData).length !== 0 ?
                                        propertyData.map( (propertyData,propertyKey) => {
                                            var propertyId = propertyData.id;
                                            var propertySummary = propertyData.summary;
                                            var propertyAddress = propertyData.address;
                                            var propertyBedrooms = propertyData.bedrooms;
                                            var propertyBathrooms = propertyData.bathrooms;
                                            var propertyReceptions = propertyData.receptions;
                                            var propertyType = propertyData.letting ? "For Rent" : "For Sale";
                                            var propertyPrice = propertyData.letting ? propertyData.letting.rent : propertyData.selling ? propertyData.selling.price : 'TBC' ;
                                            var propertyDate = new Date(propertyData.created).toLocaleString('en-us',{month:'long', day:'numeric', year:'numeric'});
                                            var propertyImage = propertyData.propertyImage;

                                            return(
                                                <div className='col-md-6 col-xl-4'>
                                                    <PropertyItem
                                                            key={propertyKey}
                                                            id={propertyId}
                                                        icon={propertyImage}
                                                        badgeColor='primary'
                                                        status={propertyType}
                                                        statusColor='primary'
                                                        title={ propertyAddress.buildingName + ' ' + propertyAddress.buildingNumber + ' ' + propertyAddress.line1 + ', ' + propertyAddress.line3}
                                                        description={propertyData.description}
                                                        summary={propertySummary}
                                                        bedrooms={propertyBedrooms}
                                                        bathrooms={propertyBathrooms}
                                                        receptions={propertyReceptions}
                                                        date={propertyDate}
                                                        budget= {'£' + propertyPrice.toLocaleString()}
                                                        progress={50}                                                      
                                                    />
                                                </div>
                                            )
                                        })
                                        : null
                                }
                            </div>
                            <div className='d-flex flex-stack flex-wrap pt-10'>
                                 <div className='fs-6 fw-bold text-gray-700'>
                                    Showing
                                    {" " + (((this.state.pageSize * this.state.pageNumber) - this.state.pageSize) + 1) + " " } to
                                    {" " + this.state.pageSize * this.state.pageNumber + " "} of
                                    {" " + this.state.totalCount + " "} entries
                                </div>
                                <ul className='pagination'>
                                    {
                                        this.createPagination(this.state.totalPageCount)
                                    }
                                </ul>
                            </div>                           
                        </>
                    }
                    </div>
                </div>
            </div>  
        )
    }
}

export default OnSaleProperty;