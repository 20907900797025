/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC,useEffect, useState} from 'react'
import {Redirect, Switch, Route, useLocation} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PublicLayout} from '../../_metronic/layout/PublicLayout'
import {PublicRoutes} from './PublicRoutes'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import HomePage from '../public/HomePage'
import OnRentProperty from '../public/OnRentProperty'
import OnSaleProperty from '../public/OnSaleProperty'
import PropertyDetail from '../public/PropertyDetail'
import {MenuComponent} from '../../_metronic/assets/ts/components'

import {Registration} from '../modules/auth/components/Registration'
import {ForgotPassword} from '../modules/auth/components/ForgotPassword'
import {Login} from '../modules/auth/components/Login'
import { requestPermission } from '../firebase/messaging-get-token'; // Adjust the import path accordingly

import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { Toast } from "@capacitor/toast";

const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual);
	if(isAuthorized){
		localStorage.setItem('user_data',JSON.stringify(isAuthorized));
		let userFullLoggedData = JSON.parse(localStorage.getItem('user_data') as string );	
		localStorage.setItem('reapit_id',userFullLoggedData.success.reapit_id);
	}

	const nullEntry: any[] = []
	const [notifications, setnotifications] = useState(nullEntry);

 	const {pathname} = useLocation();
	 
	/*
	const register = () => {
		 console.log('Initializing HomePage');
 
		 // Register with Apple / Google to receive push via APNS/FCM
		 PushNotifications.register();
 
		 // On success, we should be able to receive notifications
		 PushNotifications.addListener('registration',
			 (token: Token) => {
				 showToast('Push registration success');
				 console.log('Push registration success, token: ' + token.value);
			 }
		 );
 
		 // Some issue with our setup and push will not work
		 PushNotifications.addListener('registrationError',
			 (error: any) => {
				 alert('Error on registration: ' + JSON.stringify(error));
			 }
		 );
 
		 // Show us the notification payload if the app is open on our device
		 PushNotifications.addListener('pushNotificationReceived',
			 (notification: PushNotificationSchema) => {
				 setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
			 }
		 );
 
		 // Method called when tapping on a notification
		 PushNotifications.addListener('pushNotificationActionPerformed',
			 (notification: ActionPerformed) => {
				 setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
			 }
		 );
	 }
	 */

    const showToast = async (msg: string) => {
        await Toast.show({
            text: msg
        })
    }

	
   useEffect(() => {
    MenuComponent.reinitialization()
  }, [pathname])

  return (
    
    <Switch>
       
		<Route path='/error' component={ErrorsPage} />      
		<Route path='/logout' component={Logout} />
		<Route path='/properties/detail/:id' component={PropertyDetail} />
		<Route path='/on_rent' component={OnRentProperty} />
		<Route path='/on_sale' component={OnSaleProperty} />

		<Route path='/' component={HomePage} exact/>

		{!isAuthorized ? (
			/*Redirect to `/auth` when user is not authorized*/
		<PublicRoutes />
		) : (
			<MasterLayout>
				<PrivateRoutes />
			</MasterLayout>
		)}
    </Switch>
  )
}

export {Routes}
