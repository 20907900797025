/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component, useState} from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import axios from 'axios'
import moment from 'moment'
import Loader from "react-loader-spinner";

import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
} from '../../../_metronic/partials/widgets'

import {
	DashboardHeader, DashboardLinks
} from '../dashboard/widgets'

var _self: DashboardWrapper

const API_URL = process.env.REACT_APP_SERVER_HOST
const GET_PROPERTY_URL = `${API_URL}`

class DashboardWrapper extends Component<any, any> {
	constructor(props) {
		super(props)

		const reapit_id = localStorage.getItem('reapit_id') as string;

		this.state = {
			dashboardData: [],	
			workOrderdData: [],	
			isLoader: true,
			isWorkLoader: true,
			reapit_id: reapit_id
		}
		_self = this
	}

	componentDidMount() {
		const requestBody = {
			pageNumber: 1,
			pageSize: 15, 
			landlordId: this.state.reapit_id,
			request_to: 'reapit'
		}
		this.fetchTransaction(requestBody)
		this.fetchWorkOrders()
	}

	fetchTransaction(requestBody) {	
		axios.get(`${GET_PROPERTY_URL}dashboard`, {params: requestBody}).then(function (response) {
			if (response.status === 200) {
				_self.setState({
					dashboardData: response.data._embedded ? response.data._embedded : [],
					isLoader: false,
				})
			}
		})
	}

	fetchWorkOrders() {
		const requestBody = {
			request_to : 'reapit',
			propertyId : 'REL210041,WOL160358',
			landlordId: this.state.reapit_id,
		}	
		axios.get(`${GET_PROPERTY_URL}work_order`, {params: requestBody}).then(function (response) {
			if (response.status === 200) {
				_self.setState({
					workOrderdData: response.data._embedded ? response.data._embedded : [],
					isWorkLoader: false,
				})
			}
		})
	}

	render() {
			
			return (
				<>
					<div className="container px-6 dashboard-container">
						<div className='row gy-5 g-xl-8 pt-1 pt-md-1'>
							{/*
							<DashboardHeader
								className='mb-xl-8'
								/>
							*/}
							<DashboardLinks
								className='mb-xl-8'
								/>
						</div>
					</div>
				</>
			)
	}
}
export default DashboardWrapper
