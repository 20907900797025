/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../_metronic/helpers'
import RangeSlider from 'react-range-slider-input'
import 'react-range-slider-input/dist/style.css'

type Props = {
  handleSearchText: (values: any) => void
  handleFilterProperty: (values: any) => void
}

const PublicHeader: FC<Props> = (props: Props) => {
  const [filterType, setFilterTypeData] = useState<any>([])
  const [filterStyle, setFilterStyleData] = useState<any>([])
  const [bedroomsFrom, setbedroomsFrom] = useState<'string'>()
  const [bedroomsTo, setbedroomsTo] = useState<'string'>()
  const [priceFrom, setpriceFrom] = useState<'string'>()
  const [priceTo, setpriceTo] = useState<'string'>()
  const [rentFrom, setrentFrom] = useState<'string'>()
  const [rentTo, setrentTo] = useState<'string'>()
  const [priceRange, setPriceRange] = useState([350000, 1350000])
  const [isDrawer, setisDrawer] = useState<boolean>(true)

  const handleSearchText = (value) => {
    props.handleSearchText(value)
  }
  const submitFilter = () => {
    const filterData = {
      style: filterStyle,
      type: filterType,
      bedroomsFrom: bedroomsFrom,
      bedroomsTo: bedroomsTo,
      priceFrom: priceRange[0],
      priceTo: priceRange[1],
      rentFrom: rentFrom,
      rentTo: rentTo,
      pageNumber: 1,
    }
    props.handleFilterProperty(filterData)
  }

  const handleStyleFilterState = (e) => {
    const {value, checked} = e.target
    if (checked) {
      setFilterStyleData([...filterStyle, value])
    } else {
      setFilterStyleData(filterStyle.filter((tool) => tool != value))
    }
  }

  const handleTypeFilterState = (e) => {
    const {value, checked} = e.target
    if (checked) {
      setFilterTypeData([...filterType, value])
    } else {
      setFilterTypeData(filterType.filter((tool) => tool != value))
    }
  }

  const handleFilterText = (e) => {
    const {value, name} = e.target
    if (name == 'bedroomsFrom') {
      setbedroomsFrom(value)
    }
    if (name == 'bedroomsTo') {
      setbedroomsTo(value)
    }
    if (name == 'rentFrom') {
      setrentFrom(value)
    }
    if (name == 'rentTo') {
      setrentTo(value)
    }
  }

  return (
    <div>
      <div className='header justify-content-start  px-2' id='kt_header'>
        <div className='d-flex'>
          <Link to='/' className='btn btn-bg-light'>
            {'< Back'}
          </Link>
        </div>
      </div>
      <div
        className='header justify-content-end  px-2'
        id='kt_header'
        style={{width: '90%', marginLeft: '10%'}}
      >
        <div className='d-flex'>
          <input
            className='form-control me-2'
            type='search'
            placeholder='Search'
            aria-label='Search'
            onChange={(e) => handleSearchText(e.target.value)}
          />
        </div>

        {/* <PublicToolbar/> */}
        <div className='card-toolbar me-2'>
          <button
            type='button'
            className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen031.svg'
              className='svg-icon-5 svg-icon-gray-500 me-1'
            />
            Filter
          </button>
          <div
            className='menu menu-sub menu-sub-dropdown w-250px w-md-500px'
            id='filter_Drwaer'
            data-kt-menu='true'
          >
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>Quick Options</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div
              className='px-7 py-3 scroll-y h-500px'
              data-kt-scroll='true'
              data-kt-scroll-activate='true'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies={
                isDrawer
                  ? '#kt_drawer_chat_messenger_header1, #kt_drawer_chat_messenger_footer1'
                  : '#kt_header, #kt_toolbar1, #kt_footer1, #kt_chat_messenger_header, #kt_chat_messenger_footer'
              }
              data-kt-scroll-wrappers={
                isDrawer ? '#filter_Drwaer' : '#kt_content, #kt_chat_messenger_body'
              }
              data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
            >
              <div className='mb-5'>
                <label className='form-label fw-bold'>Property Type:</label>
                <div className='d-flex flex-wrap justify-content-start mb-3'>
                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='house'
                        onChange={(e) => handleTypeFilterState(e)}
                      />
                      <span className='form-check-label'>House</span>
                    </label>
                  </div>
                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='bungalow'
                        onChange={(e) => handleTypeFilterState(e)}
                      />
                      <span className='form-check-label'>Bungalow</span>
                    </label>
                  </div>

                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='flatApartment'
                        onChange={(e) => handleTypeFilterState(e)}
                      />
                      <span className='form-check-label'>Flat / Apartment</span>
                    </label>
                  </div>

                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='maisonette'
                        onChange={(e) => handleTypeFilterState(e)}
                      />
                      <span className='form-check-label'>Maisonette</span>
                    </label>
                  </div>

                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='land'
                        onChange={(e) => handleTypeFilterState(e)}
                      />
                      <span className='form-check-label'>Land</span>
                    </label>
                  </div>

                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='farm'
                        onChange={(e) => handleTypeFilterState(e)}
                      />
                      <span className='form-check-label'>Farm</span>
                    </label>
                  </div>

                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='cottage'
                        onChange={(e) => handleTypeFilterState(e)}
                      />
                      <span className='form-check-label'>Cottage</span>
                    </label>
                  </div>

                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='studio'
                        onChange={(e) => handleTypeFilterState(e)}
                      />
                      <span className='form-check-label'>Studio</span>
                    </label>
                  </div>

                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='townhouse'
                        onChange={(e) => handleTypeFilterState(e)}
                      />
                      <span className='form-check-label'>Townhouse</span>
                    </label>
                  </div>
                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='developmentPlot'
                        onChange={(e) => handleTypeFilterState(e)}
                      />
                      <span className='form-check-label'>Development Plot</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className='mb-5'>
                <label className='form-label fw-bold'>Property Style:</label>
                <div className='d-flex flex-wrap justify-content-start mb-3'>
                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='terraced'
                        onChange={(e) => handleStyleFilterState(e)}
                      />
                      <span className='form-check-label'>Terraced</span>
                    </label>
                  </div>
                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='endTerrace'
                        onChange={(e) => handleStyleFilterState(e)}
                      />
                      <span className='form-check-label'>End Terrace</span>
                    </label>
                  </div>
                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='detached'
                        onChange={(e) => handleStyleFilterState(e)}
                      />
                      <span className='form-check-label'>Detached</span>
                    </label>
                  </div>
                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='semiDetached'
                        onChange={(e) => handleStyleFilterState(e)}
                      />
                      <span className='form-check-label'>Semi-Detached</span>
                    </label>
                  </div>
                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='linkDetached'
                        onChange={(e) => handleStyleFilterState(e)}
                      />
                      <span className='form-check-label'>Link Detached</span>
                    </label>
                  </div>
                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='mews'
                        onChange={(e) => handleStyleFilterState(e)}
                      />
                      <span className='form-check-label'>Mews</span>
                    </label>
                  </div>
                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='basement'
                        onChange={(e) => handleStyleFilterState(e)}
                      />
                      <span className='form-check-label'>Basement</span>
                    </label>
                  </div>
                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='lowerGroundFloor'
                        onChange={(e) => handleStyleFilterState(e)}
                      />
                      <span className='form-check-label'>Lower Ground Floor</span>
                    </label>
                  </div>
                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='groundFloor'
                        onChange={(e) => handleStyleFilterState(e)}
                      />
                      <span className='form-check-label'>Ground Floor</span>
                    </label>
                  </div>
                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='firstFloor'
                        onChange={(e) => handleStyleFilterState(e)}
                      />
                      <span className='form-check-label'>First Floor</span>
                    </label>
                  </div>
                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='upperFloor'
                        onChange={(e) => handleStyleFilterState(e)}
                      />
                      <span className='form-check-label'>Upper Floor</span>
                    </label>
                  </div>
                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='upperFloorWithLift'
                        onChange={(e) => handleStyleFilterState(e)}
                      />
                      <span className='form-check-label'>Upper Floor with Lift</span>
                    </label>
                  </div>
                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='upperFloor'
                        onChange={(e) => handleStyleFilterState(e)}
                      />
                      <span className='form-check-label'>Penthouse</span>
                    </label>
                  </div>
                  <div className='w-50 mb-2'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='upperFloorWithLift'
                        onChange={(e) => handleStyleFilterState(e)}
                      />
                      <span className='form-check-label'>Duplex</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className='row mb-8'>
                <label className='form-label fw-bold'>Bedrooms:</label>
                <div className='col-6'>
                  <div>
                    <div className='d-flex justify-content-between mb-4'>
                      <div className=''>
                        <small>From</small>
                      </div>
                    </div>
                    <select
                      className='form-select form-select-solid'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      defaultValue={bedroomsFrom}
                      name='bedroomsFrom'
                      onChange={(e) => handleFilterText(e)}
                    >
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4</option>
                      <option value='5'>5</option>
                    </select>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='d-flex justify-content-between mb-4'>
                    <div className=''>
                      <small>To</small>
                    </div>
                  </div>
                  <select
                    className='form-select form-select-solid'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    defaultValue={bedroomsFrom}
                    name='bedroomsTo'
                    onChange={(e) => handleFilterText(e)}
                  >
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                    <option value='5'>5</option>
                    <option value=''>Any</option>
                  </select>
                </div>
              </div>

              <div className='row mb-8'>
                <label className='form-label fw-bold'>Price:</label>
                <div className='w-full'>
                  <div className='d-flex justify-content-between mb-4'>
                    <div className=''>
                      <small>From £{priceRange[0].toLocaleString()}</small>
                    </div>
                    <div className=''>
                      <small>To £{priceRange[1].toLocaleString()}</small>
                    </div>
                  </div>
                  <RangeSlider
                    id='priceRange'
                    class='my-3'
                    min={50000}
                    max={3500000}
                    step={5000}
                    value={priceRange}
                    onInput={setPriceRange}
                  />
                </div>
              </div>

              <div className='d-flex justify-content-end'>
                <button
                  type='reset'
                  className='btn btn-sm btn-white btn-active-light-primary me-2'
                  data-kt-menu-dismiss='true'
                >
                  Reset
                </button>

                <button
                  type='submit'
                  className='btn btn-sm btn-primary'
                  data-kt-menu-dismiss='true'
                  onClick={(e) => submitFilter()}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {PublicHeader}
