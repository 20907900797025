import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import DashboardWrapper from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {PropertiesWrapper} from '../pages/properties/PropertiesWrapper'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const TenanciesPage = lazy(() => import('../modules/profile/components/Tenancies'))
  const PropertyList = lazy(() => import('../modules/profile/components/Properties'))
  const PropertyDetail = lazy(() => import('../modules/profile/components/property/PropertyDetail'))
  const PropertyTransactions = lazy(() => import('../modules/profile/components/property/PropertyTransactions'))
  const PropertyDocuments = lazy(() => import('../modules/profile/components/property/PropertyDocuments'))
  const PropertyTenancies = lazy(() => import('../modules/profile/components/property/PropertyTenancies'))
  const PropertyOffers = lazy(() => import('../modules/profile/components/property/PropertyOffers'))
  const PropertyImages = lazy(() => import('../modules/profile/components/property/PropertyImages'))
  const PropertyInfo = lazy(() => import('../modules/profile/components/property/PropertyInfo'))

  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const RecentActivity = lazy(() => import('../modules/profile/RecentActivity'))
  const DemoProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const ValuationForm = lazy(() => import('../pages/forms/Valuation'))
  const ContactUs = lazy(() => import('../pages/forms/ContactUs'))
  const Documents = lazy(() => import('../modules/documents/Documents') )
  const WorkOrders = lazy(() => import('../modules/work-orders/WorkOrders') )
  const WorkOrderPage = lazy(() => import('../modules/work-orders/WorkOrder') )
  const TransactionPage = lazy(() => import('../modules/transactions/Transaction') );

  document.body.classList.add('aside-enabled')
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        
        <Route path='/profile/transaction/:id' component={TransactionPage} />
        <Route path='/profile/work-order/:id' component={WorkOrderPage} />

        <Route path='/profile/properties' component={PropertyList} exact />
        <Route path='/profile' component={ProfilePage} />

        {/* Single Entity Routes */}
        <Route path='/profile/tenancies/:id' component={TenanciesPage} />
        <Route path='/recent-activity' component={RecentActivity} />

        {/* Property Routes */}
        <Route path='/profile/property/' component={PropertyList} exact/>
        <Route path='/profile/property/detail/:id' component={PropertyDetail} />
        <Route path='/profile/property/info/:id' component={PropertyInfo} />
        <Route path='/profile/property/documents/:id' component={PropertyDocuments} />
        <Route path='/profile/property/transactions/:id' component={PropertyTransactions} />
        <Route path='/profile/property/tenancies/:id' component={PropertyTenancies} />
        <Route path='/profile/property/offers/:id' component={PropertyOffers} />
        <Route path='/profile/property/images/:id' component={PropertyImages} />

        
        {/* Forms */}
        <Route path='/forms/valuation/' component={ValuationForm} />
        <Route path='/forms/contact/' component={ContactUs} />

        <Route path='/documents' component={Documents} />

        <Route path='/work-orders' component={WorkOrders} />

        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/pages/profile' component={DemoProfilePage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />

        <Route path='/apps/chat' component={ChatPage} />

        <Route path='/menu-test' component={MenuTestPage} />

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
