/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import {Link, withRouter} from 'react-router-dom'
import axios from 'axios'
import Loader from 'react-loader-spinner'
import {PublicHeader} from './PublicHeader'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import LetOffer from '../pages/forms/LetOffer'
import BookViewing from '../pages/forms/BookViewing'
import BookValuation from '../pages/forms/BookValuation'
import AskQuestion from '../pages/forms/AskQuestion'

const API_URL = process.env.REACT_APP_SERVER_HOST
const GET_PROPERTY_URL = `${API_URL}property`
const GET_TENANCIES_URL = `${API_URL}tenancies`
const GET_PROPERTY_DOCUMENT_URL = `${API_URL}documents`
const GET_PROPERTY_OFFER_URL = `${API_URL}offers`
const GET_PROPERTY_DOWNLOAD_URL = `${API_URL}download`

var _self: PropertyDetail

class PropertyDetail extends Component<any, any> {
  constructor(props) {
    super(props)

    const propertyId = this.props.match.params.id

    this.state = {
      propertyData: [],
      tenanciesData: [],
      offerData: [],
      documentData: [],
      propertyImageList: [],
      isLoader: true,
      propertyId: propertyId,
    }

    _self = this
    this.fetchProperty = this.fetchProperty.bind(this)
  }

  componentDidMount() {
    const requestBody = {
      request_to: 'reapit',
      propertyId: _self.state.propertyId,
    }
    this.fetchProperty(requestBody)
  }

  fetchProperty(requestBody) {
    _self.setState({
      isLoader: true,
    })
    axios.get(`${GET_PROPERTY_URL}`, {params: requestBody}).then(function (response) {
      if (response.status === 200) {
        _self.setState({
          isLoader: false,
          activeTab: 'info',
          propertyData: response.data._embedded ? response.data._embedded[0] : [],
          propertyImageList: response.data._embedded
            ? response.data._embedded[0]['propertyImageList']
            : [],
        })
      }
    })
  }

  getPropertyTenancies() {
    _self.setState({
      isLoader: true,
    })
    const requestBody = {
      request_to: 'reapit',
      propertyId: _self.state.propertyId,
    }

    axios.get(`${GET_TENANCIES_URL}`, {params: requestBody}).then(function (tenanciesResponse) {
      if (tenanciesResponse.status === 200) {
        _self.setState({
          isLoader: false,
          activeTab: 'tenancies',
          tenanciesData: tenanciesResponse.data._embedded ? tenanciesResponse.data._embedded : [],
        })
      }
    })
  }

  getPropertyDocument() {
    _self.setState({
      isLoader: true,
    })
    const requestBody = {
      request_to: 'reapit',
      associatedId: _self.state.propertyId,
      associatedType: 'property',
    }

    axios.get(`${GET_PROPERTY_DOCUMENT_URL}`, {params: requestBody}).then(function (response) {
      if (response.status === 200) {
        _self.setState({
          isLoader: false,
          activeTab: 'document',
          documentData: response.data._embedded ? response.data._embedded : [],
        })
      }
    })
  }

  getPropertyOffer() {
    _self.setState({
      isLoader: true,
    })
    const requestBody = {
      request_to: 'reapit',
      propertyId: _self.state.propertyId,
    }

    axios.get(`${GET_PROPERTY_OFFER_URL}`, {params: requestBody}).then(function (response) {
      if (response.status === 200) {
        _self.setState({
          isLoader: false,
          activeTab: 'offer',
          offerData: response.data._embedded ? response.data._embedded : [],
        })
      }
    })
  }

  getPriceQualifier(qualifier) {
    switch (qualifier) {
      case 'askingPrice':
        return 'Asking Price'
      case 'priceOnApplication':
        return 'POA'
      case 'guidePrice':
        return 'Guide Price'
      case 'offersInRegion':
        return 'Offers in the region of'
      case 'offersOver':
        return 'Offers Over'
      case 'offersInExcess':
        return 'Offers in Excess'
      case 'fixedPrice':
        return 'Fixed Price'
      case 'priceReducedTo':
        return 'Price Reduced to'
    }
  }

  getDownload(event, downloadId, fileName) {
    event.preventDefault()

    const documentTitle = document.getElementById('document_title_' + downloadId)
    const documentLoader = document.getElementById('document_loader_' + downloadId)

    if (documentTitle) {
      documentTitle.style.display = 'none'
    }

    if (documentLoader) {
      documentLoader.style.display = 'block'
    }

    const requestBody = {
      request_to: 'reapit',
      downloadId: downloadId,
      fileName: fileName,
    }

    axios({
      url: GET_PROPERTY_DOWNLOAD_URL,
      params: requestBody,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      if (documentTitle) {
        documentTitle.style.display = 'block'
      }
      if (documentLoader) {
        documentLoader.style.display = 'none'
      }

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  }

  render() {
    const propertyData = this.state.propertyData
    const propertyImageList = this.state.propertyImageList
    const tenanciesData = this.state.tenanciesData
    const offerData = this.state.offerData
    const documentData = this.state.documentData
    console.log(propertyData)
    const propertyAddress = propertyData.address ? propertyData.address : []
    const propertyType = propertyData.letting ? 'For Rent' : 'For Sale'
    const listingLink = propertyData.letting ? '/on_rent' : '/on_sale'
    const propertyPrice = propertyData.letting
      ? propertyData.letting.rent
      : propertyData.selling
      ? propertyData.selling.price
      : 0
    const propertyPriceQualifier = this.getPriceQualifier(
      propertyData.letting
        ? propertyData.letting.rent
        : propertyData.selling
        ? propertyData.selling.qualifier
        : 0
    )
    const propertyPostCode = propertyAddress.postcode
    const propertyCurrency = propertyData.currency
    const propertyDate = new Date(propertyData.created).toLocaleString('en-us', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })

    return (
      <>
        {this.state.isLoader === true ? (
          <div className='propertyLoader' style={{textAlign: 'center', margin: '50px'}}>
            <Loader type='Oval' color='#B0E670' height={150} width={150} />
          </div>
        ) : (
          <>
            <div className='row pb-4'>
              <div className='col-sm-12 col-xl-12 text-center'>
                <Link to={listingLink} className='btn btn-bg-light'>
                  {'< Back to Search'}
                </Link>
              </div>
            </div>
            {/* begin::header section */}
            <div className='row g-5 g-xl-8 bg-prgreymed'>
              <div className='container'>
                <div className='card bg-prgreymed text-center pt-9 pb-0'>
                  <div className='card-body pt-9 pb-0'>
                    <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                      <div className='flex-grow-1'>
                        <h2 className='text-3xl'>
                          {propertyAddress.buildingName +
                            ' ' +
                            propertyAddress.buildingNumber +
                            ' ' +
                            propertyAddress.line1 +
                            ', ' +
                            propertyAddress.line3}
                        </h2>
                        <h3>
                          {propertyPriceQualifier} £{propertyPrice.toLocaleString()}
                        </h3>
                        <div className='d-flex flex-column'>
                          <div className='d-flex flex-wrap justify-content-center'>
                            <div className='border border-gray-300 border-dashed rounded h-50px py-3 px-4 me-4 mb-3 fit-content'>
                              <div className='fs-6 text-gray-800 fw-bolder'>
                                {propertyData.bedrooms}
                                <span className='svg-icon svg-icon-primary svg-icon-2x ms-2'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24px'
                                    height='24px'
                                    viewBox='0 0 24 24'
                                    version='1.1'
                                  >
                                    <title>Stockholm-icons / Home / Bed</title>
                                    <desc>Created with Sketch.</desc>
                                    <defs />
                                    <g
                                      stroke='none'
                                      stroke-width='1'
                                      fill='none'
                                      fill-rule='evenodd'
                                    >
                                      <rect x='0' y='0' width='24' height='24' />
                                      <path
                                        d='M4,22 L2,22 C2,19.2385763 4.23857625,18 7,18 L17,18 C19.7614237,18 22,19.2385763 22,22 L20,22 C20,20.3431458 18.6568542,20 17,20 L7,20 C5.34314575,20 4,20.3431458 4,22 Z'
                                        fill='#000000'
                                        fill-rule='nonzero'
                                        opacity='0.3'
                                      />
                                      <rect
                                        fill='#000000'
                                        x='1'
                                        y='14'
                                        width='22'
                                        height='6'
                                        rx='1'
                                      />
                                      <path
                                        d='M13,13 L11,13 L11,12 C11,11.4477153 10.5522847,11 10,11 L6,11 C5.44771525,11 5,11.4477153 5,12 L5,13 L4,13 C3.44771525,13 3,12.5522847 3,12 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12 C21,12.5522847 20.5522847,13 20,13 L19,13 L19,12 C19,11.4477153 18.5522847,11 18,11 L14,11 C13.4477153,11 13,11.4477153 13,12 L13,13 Z'
                                        fill='#000000'
                                        opacity='0.3'
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-4 mb-3'>
                              <div className='fs-6 text-gray-800 fw-bolder'>
                                {propertyData.bathrooms}
                                <span className='svg-icon svg-icon-primary svg-icon-2x ms-2'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24px'
                                    height='24px'
                                    viewBox='0 0 24 24'
                                    version='1.1'
                                  >
                                    <title>Stockholm-icons / Home / Bath</title>
                                    <desc>Created with Sketch.</desc>
                                    <defs />
                                    <g
                                      stroke='none'
                                      stroke-width='1'
                                      fill='none'
                                      fill-rule='evenodd'
                                    >
                                      <path
                                        d='M6,10 C6,10.5522847 5.55228475,11 5,11 C4.44771525,11 4,10.5522847 4,10 L4,6 C4,4.34314575 5.34314575,3 7,3 C8.65685425,3 10,4.34314575 10,6 C10,6.55228475 9.55228475,7 9,7 C8.44771525,7 8,6.55228475 8,6 C8,5.44771525 7.55228475,5 7,5 C6.44771525,5 6,5.44771525 6,6 L6,10 Z'
                                        fill='#000000'
                                        fill-rule='nonzero'
                                        opacity='0.3'
                                      />
                                      <path
                                        d='M6,20 L4,20 C4,17.2385763 6.23857625,16 9,16 L15,16 C17.7614237,16 20,17.2385763 20,20 L18,20 C18,18.3431458 16.6568542,18 15,18 L9,18 C7.34314575,18 6,18.3431458 6,20 Z'
                                        fill='#000000'
                                        fill-rule='nonzero'
                                        opacity='0.3'
                                      />
                                      <path
                                        d='M2,9 L23,9 L22.4942047,13.5521576 C22.2128532,16.084321 20.0725321,18 17.524786,18 L7.47521397,18 C4.92746788,18 2.78714678,16.084321 2.50579529,13.5521576 L2,9 Z M19.25,11.5 C19.25,12.9305987 18.694479,14.0416408 17.55,14.9 C17.2186292,15.1485281 17.1514719,15.6186292 17.4,15.95 C17.6485281,16.2813708 18.1186292,16.3485281 18.45,16.1 C19.9721877,14.9583592 20.75,13.4027346 20.75,11.5 C20.75,11.0857864 20.4142136,10.75 20,10.75 C19.5857864,10.75 19.25,11.0857864 19.25,11.5 Z'
                                        fill='#000000'
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-4 mb-3'>
                              <div className='fs-6 text-gray-800 fw-bolder'>
                                {propertyData.receptions}
                                <span className='svg-icon svg-icon-primary svg-icon-2x ms-2'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24px'
                                    height='24px'
                                    viewBox='0 0 24 24'
                                    version='1.1'
                                  >
                                    <title>Stockholm-icons / Home / Couch</title>
                                    <desc>Created with Sketch.</desc>
                                    <defs />
                                    <g
                                      stroke='none'
                                      stroke-width='1'
                                      fill='none'
                                      fill-rule='evenodd'
                                    >
                                      <path
                                        d='M6,20 L4,20 C4,17.2385763 6.23857625,16 9,16 L15,16 C17.7614237,16 20,17.2385763 20,20 L18,20 C18,18.3431458 16.6568542,18 15,18 L9,18 C7.34314575,18 6,18.3431458 6,20 Z'
                                        fill='#000000'
                                        fill-rule='nonzero'
                                        opacity='0.3'
                                      />
                                      <path
                                        d='M23,8 L21.173913,8 C20.0693435,8 19.173913,8.8954305 19.173913,10 L19.173913,12 C19.173913,12.5522847 18.7261978,13 18.173913,13 L5.86956522,13 C5.31728047,13 4.86956522,12.5522847 4.86956522,12 L4.86956522,10 C4.86956522,8.8954305 3.97413472,8 2.86956522,8 L1,8 C1,6.34314575 2.34314575,5 4,5 L20,5 C21.6568542,5 23,6.34314575 23,8 Z'
                                        fill='#000000'
                                        opacity='0.3'
                                      />
                                      <path
                                        d='M23,10 L23,15 C23,16.6568542 21.6568542,18 20,18 L4,18 C2.34314575,18 1,16.6568542 1,15 L1,10 L2.86956522,10 L2.86956522,12 C2.86956522,13.6568542 4.21271097,15 5.86956522,15 L18.173913,15 C19.8307673,15 21.173913,13.6568542 21.173913,12 L21.173913,10 L23,10 Z'
                                        fill='#000000'
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Link to='#' type="button" className="btn btn-primary rounded">Book a viewing</Link>
                          <div className='col-md-10 mx-auto mb-4 mt-10'>
                            <div
                              id='carouselExampleIndicators'
                              className='carousel slide w-100'
                              data-bs-ride='carousel'
                            >
                              <div className='carousel-indicators'>
                                {Object.keys(propertyImageList).length !== 0
                                  ? propertyImageList.map((imageInfo, imageKey) => {
                                      return (
                                        <button
                                          type='button'
                                          data-bs-target='#carouselExampleIndicators'
                                          data-bs-slide-to={imageKey}
                                          className={imageKey == 0 ? 'active' : ''}
                                          aria-current='true'
                                          aria-label='Slide 1'
                                        ></button>
                                      )
                                    })
                                  : null}
                              </div>
                              <div className='carousel-inner'>
                                {Object.keys(propertyImageList).length !== 0
                                  ? propertyImageList.map((imageInfo, imageKey) => {
                                      return (
                                        <div
                                          className={
                                            imageKey == 2 ? 'carousel-item active' : 'carousel-item'
                                          }
                                          key={imageKey}
                                        >
                                          <img
                                            src={imageInfo.url}
                                            className='d-block w-100'
                                            alt='...'
                                          />
                                        </div>
                                      )
                                    })
                                  : null}
                              </div>
                              <button
                                className='carousel-control-prev'
                                type='button'
                                data-bs-target='#carouselExampleIndicators'
                                data-bs-slide='prev'
                              >
                                <span
                                  className='carousel-control-prev-icon'
                                  aria-hidden='true'
                                ></span>
                                <span className='visually-hidden'>Previous</span>
                              </button>
                              <button
                                className='carousel-control-next'
                                type='button'
                                data-bs-target='#carouselExampleIndicators'
                                data-bs-slide='next'
                              >
                                <span
                                  className='carousel-control-next-icon'
                                  aria-hidden='true'
                                ></span>
                                <span className='visually-hidden'>Next</span>
                              </button>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* begin::detail section */}
            <div className='d-flex flex-column flex-root container-fixed bg-body w-100 px-10'>
              <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
                <div className='d-flex flex-column flex-column-fluid'>
                  <div className='d-flex flex-row h-300px'>
                    <div className='d-flex flex-column flex-row-fluid'>
                      <div className='row'>
                        <div className='col-sm-12 col-xl-12 px-0'>
                          {/* }
                          <ul
                            className='nav nav-tabs nav-line-tabs mb-5 fs-6'
                            style={{backgroundColor: '#fff', borderRadius: '10px'}}
                          >
                            <li className='nav-item'>
                              <a
                                className={
                                  this.state.activeTab === 'info' ? 'nav-link active' : 'nav-link'
                                }
                                data-bs-toggle='tab'
                                href='#info'
                              >
                                Description
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                className={
                                  this.state.activeTab === 'tenancies'
                                    ? 'nav-link active'
                                    : 'nav-link'
                                }
                                data-bs-toggle='tab'
                                href='#map'
                              >
                                Map
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                className={
                                  this.state.activeTab === 'epc' ? 'nav-link active' : 'nav-link'
                                }
                                data-bs-toggle='tab'
                                href='#epc'
                              >
                                EPC
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                className={
                                  this.state.activeTab === 'epc' ? 'nav-link active' : 'nav-link'
                                }
                                data-bs-toggle='tab'
                                href='#epc'
                              >
                                Video
                              </a>
                            </li>
                          </ul>
                            */}
                          <div
                            className='tab-content'
                            id='info_container'
                            style={{backgroundColor: '#fff', padding: '10px', borderRadius: '5px'}}
                          >
                            <div className='tab-pane fade active show container' id='info' role='tabpanel'>
                              <div className='card my-5'>
                                <div className='card-body p-0'>
                                  <h2>Available for Sale</h2>
                                  <div className='d-flex flex-column'>
                                  <div className='d-flex flex-wrap justify-content-between mt-5'>
                                  <div className='d-flex flex-wrap justify-content-between mt-2 h-50px'>
                                    <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-4 mb-3'>
                                      <div className='fs-6 text-gray-800 fw-bolder'>
                                        {propertyData.bedrooms}
                                        <span className='svg-icon svg-icon-primary svg-icon-2x ms-2'>
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24px'
                                            height='24px'
                                            viewBox='0 0 24 24'
                                            version='1.1'
                                          >
                                            <title>Stockholm-icons / Home / Bed</title>
                                            <desc>Created with Sketch.</desc>
                                            <defs />
                                            <g
                                              stroke='none'
                                              stroke-width='1'
                                              fill='none'
                                              fill-rule='evenodd'
                                            >
                                              <rect x='0' y='0' width='24' height='24' />
                                              <path
                                                d='M4,22 L2,22 C2,19.2385763 4.23857625,18 7,18 L17,18 C19.7614237,18 22,19.2385763 22,22 L20,22 C20,20.3431458 18.6568542,20 17,20 L7,20 C5.34314575,20 4,20.3431458 4,22 Z'
                                                fill='#000000'
                                                fill-rule='nonzero'
                                                opacity='0.3'
                                              />
                                              <rect
                                                fill='#000000'
                                                x='1'
                                                y='14'
                                                width='22'
                                                height='6'
                                                rx='1'
                                              />
                                              <path
                                                d='M13,13 L11,13 L11,12 C11,11.4477153 10.5522847,11 10,11 L6,11 C5.44771525,11 5,11.4477153 5,12 L5,13 L4,13 C3.44771525,13 3,12.5522847 3,12 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12 C21,12.5522847 20.5522847,13 20,13 L19,13 L19,12 C19,11.4477153 18.5522847,11 18,11 L14,11 C13.4477153,11 13,11.4477153 13,12 L13,13 Z'
                                                fill='#000000'
                                                opacity='0.3'
                                              />
                                            </g>
                                          </svg>
                                        </span>
                                      </div>
                                    </div>
                                    <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-4 mb-3'>
                                      <div className='fs-6 text-gray-800 fw-bolder'>
                                        {propertyData.bathrooms}
                                        <span className='svg-icon svg-icon-primary svg-icon-2x ms-2'>
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24px'
                                            height='24px'
                                            viewBox='0 0 24 24'
                                            version='1.1'
                                          >
                                            <title>Stockholm-icons / Home / Bath</title>
                                            <desc>Created with Sketch.</desc>
                                            <defs />
                                            <g
                                              stroke='none'
                                              stroke-width='1'
                                              fill='none'
                                              fill-rule='evenodd'
                                            >
                                              <path
                                                d='M6,10 C6,10.5522847 5.55228475,11 5,11 C4.44771525,11 4,10.5522847 4,10 L4,6 C4,4.34314575 5.34314575,3 7,3 C8.65685425,3 10,4.34314575 10,6 C10,6.55228475 9.55228475,7 9,7 C8.44771525,7 8,6.55228475 8,6 C8,5.44771525 7.55228475,5 7,5 C6.44771525,5 6,5.44771525 6,6 L6,10 Z'
                                                fill='#000000'
                                                fill-rule='nonzero'
                                                opacity='0.3'
                                              />
                                              <path
                                                d='M6,20 L4,20 C4,17.2385763 6.23857625,16 9,16 L15,16 C17.7614237,16 20,17.2385763 20,20 L18,20 C18,18.3431458 16.6568542,18 15,18 L9,18 C7.34314575,18 6,18.3431458 6,20 Z'
                                                fill='#000000'
                                                fill-rule='nonzero'
                                                opacity='0.3'
                                              />
                                              <path
                                                d='M2,9 L23,9 L22.4942047,13.5521576 C22.2128532,16.084321 20.0725321,18 17.524786,18 L7.47521397,18 C4.92746788,18 2.78714678,16.084321 2.50579529,13.5521576 L2,9 Z M19.25,11.5 C19.25,12.9305987 18.694479,14.0416408 17.55,14.9 C17.2186292,15.1485281 17.1514719,15.6186292 17.4,15.95 C17.6485281,16.2813708 18.1186292,16.3485281 18.45,16.1 C19.9721877,14.9583592 20.75,13.4027346 20.75,11.5 C20.75,11.0857864 20.4142136,10.75 20,10.75 C19.5857864,10.75 19.25,11.0857864 19.25,11.5 Z'
                                                fill='#000000'
                                              />
                                            </g>
                                          </svg>
                                        </span>
                                      </div>
                                    </div>
                                    <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-4 mb-3'>
                                      <div className='fs-6 text-gray-800 fw-bolder'>
                                        {propertyData.receptions}
                                        <span className='svg-icon svg-icon-primary svg-icon-2x ms-2'>
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24px'
                                            height='24px'
                                            viewBox='0 0 24 24'
                                            version='1.1'
                                          >
                                            <title>Stockholm-icons / Home / Couch</title>
                                            <desc>Created with Sketch.</desc>
                                            <defs />
                                            <g
                                              stroke='none'
                                              stroke-width='1'
                                              fill='none'
                                              fill-rule='evenodd'
                                            >
                                              <path
                                                d='M6,20 L4,20 C4,17.2385763 6.23857625,16 9,16 L15,16 C17.7614237,16 20,17.2385763 20,20 L18,20 C18,18.3431458 16.6568542,18 15,18 L9,18 C7.34314575,18 6,18.3431458 6,20 Z'
                                                fill='#000000'
                                                fill-rule='nonzero'
                                                opacity='0.3'
                                              />
                                              <path
                                                d='M23,8 L21.173913,8 C20.0693435,8 19.173913,8.8954305 19.173913,10 L19.173913,12 C19.173913,12.5522847 18.7261978,13 18.173913,13 L5.86956522,13 C5.31728047,13 4.86956522,12.5522847 4.86956522,12 L4.86956522,10 C4.86956522,8.8954305 3.97413472,8 2.86956522,8 L1,8 C1,6.34314575 2.34314575,5 4,5 L20,5 C21.6568542,5 23,6.34314575 23,8 Z'
                                                fill='#000000'
                                                opacity='0.3'
                                              />
                                              <path
                                                d='M23,10 L23,15 C23,16.6568542 21.6568542,18 20,18 L4,18 C2.34314575,18 1,16.6568542 1,15 L1,10 L2.86956522,10 L2.86956522,12 C2.86956522,13.6568542 4.21271097,15 5.86956522,15 L18.173913,15 C19.8307673,15 21.173913,13.6568542 21.173913,12 L21.173913,10 L23,10 Z'
                                                fill='#000000'
                                              />
                                            </g>
                                          </svg>
                                        </span>
                                      </div>
                                    </div>
                                    </div>
                                    <div className='w-50'>
                                        <img
                                            alt='Do you want to sell your property?'
                                            className='img-fluid'
                                            src={toAbsoluteUrl('/media/prospect/property-results-sales-desktop.jpg')}
                                          />
                                    </div>
                                  </div>
                                  </div>
                                  <div className='row m7-4 my-5'>
                                    <div className='col-lg-6 fw-bold text-muted'>
                                      <h3>Key features</h3>
                                      <div className='mb-2'>{propertyData.summary}</div>
                                      <div className='mb-2'>{propertyData.description}</div>
                                    </div>
                                    <div className='col-lg-3'>
                                    <div className='my-3'>
                                      <BookViewing />
                                    </div>
                                    <div className='my-3'>
                                      <LetOffer />
                                    </div>
                                    <div className='my-3'>
                                      <AskQuestion />
                                    </div>
                                    </div>
                                  </div>
                                  </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-8 col-xl-8'>
                                  <div className='card-title m-0'>
                                    <div className='fs-3 fw-bolder text-dark'></div>
                                  </div>
                                 

                                  <div className='row'>
                                    <div className='col-md-12 col-xl-12'>
                                      <div className='card-toolbar'>
                                        <span
                                          className={`badge badge-light-${propertyData.badgeColor} fw-bolder me-auto py-3`}
                                        >
                                          {propertyType}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='tab-pane fade' id='map' role='tabpanel'>
                              Output google map
                            </div>
                            <div className='tab-pane fade' id='map' role='tabpanel'>
                              EPC
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    )
  }
}

export default withRouter(PropertyDetail)
