/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers';

var _self: AskQuestion;

class AskQuestion extends Component<any, any> {

    constructor(props: any) {
        super(props);
    
        
        _self = this;
      }

    render(){
        return (
            <>
<button
type='button'
className='btn btn-secondary w-100 rounded'
data-bs-toggle='modal'
data-bs-target='#kt_modal_question'
>
Ask a question
</button>
<div className='modal fade' tabIndex={-1} id='kt_modal_question'>
<div className='modal-dialog'>
  <div className='modal-content'>
    <div className='modal-header'>
      <h5 className='modal-title'>Ask a question</h5>
      <div
        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
        data-bs-dismiss='modal'
        aria-label='Close'
      >
        {<KTSVG
          path='/media/icons/duotune/arrows/arr061.svg'
          className='svg-icon svg-icon-2x'
        />}
      </div>
    </div>
    <div className='modal-body'>
      <p>Ask a question form</p>
    </div>
    <div className='modal-footer'>
      <button
        type='button'
        className='btn btn-light'
        data-bs-dismiss='modal'
      >
        Close
      </button>
      <button type='button' className='btn btn-primary'>
        Ask the question  
      </button>
    </div>
  </div>
</div>
</div>
</>

        )

    }

}

export default AskQuestion;