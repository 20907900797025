import {refreshToken} from '../../app/modules/auth/redux/AuthCRUD';
export default function setupAxios(axios: any, store: any) {

  axios.interceptors.request.use(  
    async (config: any) => {

      if(config.url && config.url === process.env.REACT_APP_REAPIT_TOKEN_URL){         
        return config;
      } else {      
        if(config.data && typeof config.data.request_to !== 'undefined' && config.data.request_to === 'server'){          
          const {
              auth: {accessToken},
          } = store.getState();
          if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
          }
        } else if (config.params && typeof config.params.request_to !== 'undefined' && config.params.request_to === 'reapit'){                    
            const reapitAccessToken = await refreshToken().then(res =>              
              config.headers.Authorization = `Bearer ${res}`
            );                   
        } else {
            const {
                auth: {accessToken},
            } = store.getState();
            if (accessToken) {
              //config.headers.Authorization = `Bearer ${accessToken}`
            }         
        }
      }
      return config
    },
    (err: any) => Promise.reject(err)
  );
}
