import React, {useRef, useEffect} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {AsideMenuMain} from './AsideMenuMain'
import {AsideMenuProfile} from './AsideMenuProfile'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {AsideMenuSearch} from './AsideMenuSearch'
import * as auth from "../../../../app/modules/auth/redux/AuthRedux";
import {
  DrawerComponent,
  MenuComponent,
  ScrollComponent,
  ToggleComponent,
} from '../../../assets/ts/components'

type Props = {
  asideMenuCSSClasses: string[]
}

const AsideMenu: React.FC<Props> = ({asideMenuCSSClasses}) => {

  const scrollRef = useRef<HTMLDivElement | null>(null)
  const {pathname} = useLocation()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const user_data = JSON.parse(localStorage.getItem('user_data') as string)
  console.log('USER: ', user_data.success.name)

  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
  }

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0
      }
    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <div
      id='kt_aside_menu_wrapper'
      ref={scrollRef}
      className='hover-scroll-overlay-y my-5 my-lg-5'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-height='auto'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-wrappers='#kt_aside_menu'
      data-kt-scroll-offset='0'
    >
      <div
        id='#kt_aside_menu'
        data-kt-menu='true'
        className={clsx(
          'menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500',
          asideMenuCSSClasses.join(' ')
        )}
      >
        <div className='px-20px'>
          <div className='btn btn-prgreen text-white text-left p-1'>
            {user_data.success.name}
          </div>
        </div>
        <div className='py-10'>
        <AsideMenuMain />
        </div>
        <div className='py-10'>
        <AsideMenuProfile />
        <div className='menu-item'>
        <a className='menu-link without-sub fs-5' onClick={logout}>Sign Out</a>
        </div>
        </div>
        <AsideMenuSearch />
      </div>
    </div>
  )
}

export {AsideMenu}
